* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "poppins", sans-serif;
}

:root {
    --white: #fff;
    --black: #000;
    --dark: #1f1f1f;
    --light: #ffff;
    --red: red;
    --text: #1d1d1def;
    --primary: #031329;
    --primary-dark: #148f87;
    --secondary: ;
    --input: #f8f8f8;
}

html,
body {
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
    background-color: #f4f5f7;
    color: var(--text);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


/* ScrollBar  */

::-webkit-scrollbar {
    width: 7px;
    height: 3px;
}

::-webkit-scrollbar-track {
    background-color: #fafafa;
    border-radius: 50px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background: #031329;
    border-radius: 50px;
}

.cs_container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 38px;
}

a {
    color: var(--primary);
}

.text-white {
    color: var(--white);
}

.cs_row {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text_center {
    text-align: center;
}

.p-2 {
    padding: 10px;
}

.px-2 {
    padding: 0 10px;
}

.py-2 {
    padding: 10px 0;
}

.my-2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mx-1 {
    margin: auto 4px;
}

.mx-2 {
    margin: auto 10px;
}

.w-100 {
    width: 100%;
}

.fw_bold {
    font-weight: 650;
}

.mx_auto {
    margin-left: auto;
    margin-right: auto;
}

.m-0 {
    margin: 0 !important;
}

.paper {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.205);
    background-color: var(--light);
    padding: 12px;
    border-radius: 7px;
}

.cs_btn,
button {
    background-color: var(--primary);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
    padding: 7px 22px;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    border: 1px solid var(--white);
    transition: 0.4s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    width: max-content;
    max-width: 100%;
}

.cs_btn:hover,
button:hover {
    text-decoration: none;
    transform: scale(1.03);
    background-color: var(--white);
    color: var(--black);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.192);
    border-color: var(--primary);
}

.cs_btn.bg_red,
button.bg_red {
    background-color: var(--red);
}

.cs_btn.sm {
    padding: 3px 14px;
}

.cs_btn.outlined {
    background-color: var(--white);
    color: var(--black);
    border-color: var(--primary);
}

.cs_icon_btn {
    border-radius: 50%;
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    padding: 0;
    font-size: 27px;
}

.cs_icon_btn.sm {
    width: 30px;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    max-height: 30px;
    min-height: 30px;
    font-size: 20px;
}

.cs_btn.active_cs_btn {
    background-color: var(--primary);
    color: var(--light);
    border-color: var(--black);
}

.btns_row .cs_btn {
    margin: 4px;
}

.page_wrapper {
    min-height: 100vh;
}

select,
textarea,
input {
    padding: 7px;
    width: 100%;
    background-color: var(--input);
    color: var(--text);
    border-radius: 4px;
    outline: none;
    border: none;
    border: 1px solid #0b6b653b;
    font-size: 16px;
}

textarea:focus,
input:focus {
    border: none;
    border: 1px solid #144283;
    /* border: 1px solid #23d1c5; */
}

textarea {
    min-height: 240px;
}

.d_none {
    display: none;
}

.sec_heading {
    text-align: center;
    width: 100%;
    padding: 15px 0;
}

.sec_heading h2 {
    font-size: 2rem;
}


/* go to top */

.go-to-top {
    position: fixed;
    bottom: 25px;
    right: 15px;
    background-color: #ffff;
    color: var(--primary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999;
    border: 2px solid var(--primary);
    box-shadow: -3px 5px 15px rgba(0, 0, 0, 0.178);
    transition: transform 0.3s linear;
    visibility: hidden;
    transform: translateX(150%);
}

.go-to-top * {
    width: 27px;
    height: 27px;
    font-size: 24px !important;
    color: var(--primary);
    fill: var(--primary);
}

.show-top {
    visibility: visible;
    transform: translateX(0);
}


/* modal */

.cs_modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.cs_modal_backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.158);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.cs_modal {
    min-width: 380px;
    max-width: 750px;
    min-height: 400px;
    max-height: 98%;
    background-color: var(--light);
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.274);
    display: flex;
    flex-direction: column;
    /* border: 1px solid var(--primary); */
    animation: up 0.4s ease-in-out;
    z-index: 999;
}

.cs_modal.ask_modal {
    min-width: 240px;
    max-width: 500px;
    min-height: 200px;
}

.cs_modal .cs_btn:hover {
    transform: none;
}

.ask_modal .cs_btn {
    padding: 4px 15px;
    font-size: 14px;
}

@keyframes up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.cs_modal_body {
    padding: 15px;
    overflow-y: auto;
}

.cs_modal_header,
.cs_modal_footer {
    position: sticky;
    width: 100%;
    background-color: var(--white);
    padding: 8px;
}

.cs_modal_header {
    top: 0;
    left: 0;
    border-bottom: 1px solid var(--primary);
}

.cs_modal_footer {
    bottom: 0;
    left: 0;
    margin-top: auto;
    border-top: 1px solid var(--primary);
}

.cs_modal_header img {
    max-width: 70px;
}


/* alert */

.cs_alert_wrapper {
    position: fixed;
    bottom: 8px;
    right: 9px;
    padding: 5px 8px;
    border-radius: 4px;
    z-index: 999;
    color: var(--light);
    max-width: 750px;
}

.cs_alert_wrapper .cs_icon_btn {
    background-color: var(--white);
    color: var(--primary);
    font-size: 28px;
    margin-left: auto;
}

.cs_alert_wrapper.red {
    background-color: #df4759;
}

.cs_alert_wrapper.green {
    background-color: #20c997;
}

.cs_alert_wrapper.yellow {
    background-color: #ffc107;
}

.cs_alert_wrapper.info {
    background-color: #467fd0;
}


/* loader */

.fixedLoading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.048);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid var(--primary);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 0px 10px #00000048;
}

.img-loader-container {
    min-height: 80px;
}

.imgloader_fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.13);
    z-index: 99999;
}

.loader-width-img {
    background-color: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -35px;
    margin-top: -35px;
}

.loaderimg-wrapp {
    width: 90%;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loaderimg-wrapp img {
    width: 100%;
    z-index: 111;
}

.spinner {
    border: 3px solid var(--white);
    border-top: 5px solid var(--primary);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* =========================== */

#hero .cs_row {
    width: 100%;
    min-height: 400px;
}

.hero_col_right,
.hero_col_left {
    width: 50%;
}

.hero_col_left h1 {
    font-size: 2.8rem;
}

.hero_col_left p {
    padding: 28px 0;
}

.hero_col_right img {
    max-width: 95%;
    margin-right: -100px;
    margin-top: 20px;
}


/*  */

#banner {
    background: url(../images/networkBg.jpg) rgba(0, 0, 0, 0.11);
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

#banner .cs_row {
    min-height: 450px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#banner .cs_row h2 {
    color: var(--light);
    font-size: 2.8rem;
    max-width: 500px;
    text-shadow: 0 0 10px #000;
    margin-bottom: 55px;
}


/*  */

.section_ {
    position: relative;
    padding: 30px 0;
    margin: 50px auto 50px auto;
}

.section_ h3 {
    font-size: 2.1rem;
    padding-bottom: 20px;
}

.section_ p {
    padding: 7px 0;
}

.section_ .cs_row {
    align-items: center;
}

.left_col,
.right_col {
    width: 50%;
}

.right_col {
    text-align: center;
}

.section_ .right_col img {
    width: 90%;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.199));
    transition: transform 0.3s linear;
}


/*  */

#courseDetails,
#courseDetails .cs_row {
    padding: 40px 0;
}

.lesson_card {
    width: 48%;
    background-color: var(--light);
    box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.226);
    border-radius: 10px;
    overflow: hidden;
    /* min-height: 100px; */
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 10px auto;
    position: relative;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.lesson_card:hover {
    transform: scale(1.03);
}

.lesson_card::after {
    position: absolute;
    bottom: -20px;
    right: -43px;
    content: "";
    width: 100px;
    height: 40px;
    background: #0a2955b2;
    transform: rotate(137deg);
}


/* .lesson_card */

.thumbnail {
    width: 140px;
    /* height: 100%; */
    /* background: #ebeeee; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 2px 6px 11px;
}

.thumbnail img {
    width: 100%;
    /* height: 100%; */
    text-align: center;
    object-fit: 100%;
}

.about_lesson_card_right {
    flex: 1;
    height: 100%;
    padding: 15px 17px;
}


/* form */

.cs_form {
    max-width: 750px;
    margin: 0 auto;
    position: relative;
}

.cs_form.loading>*:not(.cs_progress_wrapper) {
    opacity: 0.5;
    pointer-events: none;
}

.cs_form.loading::after {
    position: absolute;
    top: 38%;
    left: 43%;
    content: "";
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid var(--primary);
    border-top: 5px solid var(--white);
    animation: spin 0.6s linear infinite;
}

.cs_form label {
    text-align: left;
    font-size: 18px;
}

.form_label_wrapper {
    max-width: 150px;
    min-width: 150px;
}

.cs_icons {
    font-size: 25px;
    margin: 0 5px;
}

.login_form {
    /* border: 1px solid var(--primary); */
    padding: 18px 25px;
    border-radius: 6px;
    background-color: var(--light);
    max-width: 580px;
    min-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.144);
}

.form_heading {
    margin: 7px auto 12px auto;
    text-align: center;
}

.login_page {
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* header */

.web_header {
    z-index: 999;
    padding-top: 8px;
    user-select: none;
    position: sticky;
    top: 0;
}

.web_header .cs_container {
    padding: 0;
}

.web_header .cs_row {
    box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.171);
    transition: top 0.4s ease;
    width: 98%;
    margin: 0 auto;
    max-width: 1300px;
    border-radius: 50px;
    border: 2px solid var(--light);
    background-color: #031329;
    padding: 0 35px;
    align-items: center;
    min-height: 45px;
}

.header_logo a,
.header_logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_logo img.mobile_logo {
    display: none;
}

.header_logo img {
    max-width: 268px;
    margin: 6px auto;
}

.header_right ul,
.header_right {
    display: flex;
    flex: 1;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
}

.header_right ul {
    list-style: none;
}

.header_right ul li .cs_navlinks,
.header_right ul li a {
    padding: 7px 17px;
    text-decoration: none;
    margin-right: 10px;
    font-weight: 600;
    font-size: 17px;
    transition: 0.3s ease;
    color: var(--light);
    border-radius: 50px;
    cursor: pointer;
}

.header_right ul li a:hover {
    color: var(--white);
}

.header_menu_btn {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    color: var(--light);
    font-size: 33px;
}


/* ================= */

.course_main_row {
    padding: 40px 0;
    align-items: flex-start;
}

.course_left {
    position: sticky;
    top: 0;
}

.all_course_list {
    width: 300px;
    background-color: var(--white);
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
    border: 1px solid rgba(128, 128, 128, 0.253);
    border-radius: 4px;
    border-top: 4px solid var(--primary);
    margin-bottom: 15px;
}

.course_left header {
    background-color: var(--primary);
    color: var(--white);
    padding: 4px 8px;
    font-weight: 600;
    font-size: 22px;
}

.course_topics_body {
    max-height: 320px;
    overflow: auto;
    padding: 4px 0;
    position: relative;
}

.course_topics_title_box {
    width: 100%;
    padding: 4px 8px;
    color: var(--text);
    cursor: pointer;
    user-select: none;
    position: relative;
    min-height: 80px;
    /* margin: 2px 0; */
    border-top: 1px solid #80808079;
    /* border-bottom: 1px solid #80808079; */
}

.course_topics_title_box.publish {
    padding-top: 8px;
}

.course_topics_title_box.publish::before {
    position: absolute;
    top: 2.2px;
    left: 8px;
    content: "";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    text-align: center;
    /* padding: 0 3px; */
    background-color: #1bbc51;
}

.course_topics_title_box.disabled {
    /* pointer-events: none; */
    opacity: 0.8;
}

.course_topics_title_box.disabled::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #67a6ff2d;
}

.course_topics_title_box p {
    font-size: 15px;
    font-weight: 600;
}

.course_topics_title_box.selected_video,
.course_topics_title_box:hover {
    background-color: #67a6ff9f;
}

.course_topics_title_box .cs_icon_btn {
    position: absolute;
    top: 7px;
    right: 5px;
    z-index: 22;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    font-size: 16px;
}

.course_topics_title_box .cs_icon_btn.completed {
    top: auto;
    bottom: 7px;
    right: 5px;
    background-color: #20c961;
    color: var(--dark);
    border-color: var(--dark);
    /* pointer-events: none; */
}

.course_right {
    flex: 1;
    margin-left: 7px;
}

.course_video_wrapper {
    border-radius: 5px;
    background-color: #0636333a;
    min-width: 100%;
    min-height: 250px;
}

.course_video_wrapper video {
    width: 100%;
    object-fit: contain;
    max-height: 450px;
    border-radius: 5px;
}

.course_description {
    padding: 25px 17px;
}

.course_description h1 {
    font-size: 2rem;
}

.lang-btn {
    margin: 7px 6px 2px 6px;
    width: 39px;
    min-width: 39px;
    max-width: 39px;
    height: 39px;
    min-height: 39px;
    max-height: 39px;
    cursor: pointer;
    user-select: none;
    border: 2px solid #03132928;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.lang-btn.selected {
    border-color: var(--primary);
}

.lang-btn span {
    width: 100%;
    height: 39px;
    background-size: cover;
    overflow: hidden;
}


/*  */

@media (max-width: 850px) {
    .header_logo img {
        max-width: 208px;
    }
    .header_right ul li .cs_navlinks,
    .header_right ul li a {
        font-size: 15px;
    }
}

@media (max-width: 988px) {
    /*  */
    .web_header {
        padding-top: 1px;
    }
    .web_header .cs_row {
        padding: 0 10px 0 10px;
        max-width: 97%;
        margin: 0 auto;
    }
    .header_menu_btn {
        display: flex;
    }
    .header_right {
        position: relative;
    }
    .header_right ul {
        position: absolute;
        flex-direction: column;
        max-width: 230px;
        border-radius: 12px;
        padding: 15px;
        top: 125%;
        opacity: 0;
        transition: all 0.3s linear;
        right: 0;
        background-color: var(--primary);
        border: 1px solid var(--light);
        box-shadow: 0 0 14px rgb(0 0 0 / 74%);
        border-top-right-radius: 5px;
        pointer-events: none;
        visibility: hidden;
    }
    .header_right ul.opened {
        top: 112%;
        opacity: 1;
        visibility: auto;
        pointer-events: auto;
        visibility: visible;
    }
    .header_right ul::before {
        position: absolute;
        top: -20px;
        right: 1px;
        content: "";
        border: 10px solid #fff;
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
    }
    .header_right ul li {
        width: 100%;
    }
    .header_right ul li .cs_navlinks,
    .header_right ul li a {
        width: 100%;
        padding: 3px 15px;
        margin: 2px auto;
        font-weight: 500;
        font-size: 15px;
        border-radius: 0;
        display: inline-block;
    }
}

@media (max-width: 768px) {
    .cs_btn,
    button {
        padding: 3px 17px;
        font-size: 15px;
    }
    .cs_container {
        padding: 0 12px;
    }
    .copyright_text * {
        font-size: 15px !important;
    }
    .cs_alert_wrapper {
        padding: 4px 6px;
        border-radius: 4px;
        max-width: 93%;
        justify-content: space-between;
    }
    .cs_icon_btn {
        margin-left: auto;
    }
    .cs_alert_wrapper p {
        font-size: 14px;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 16px;
    }
    /*  */
    #hero .cs_row {
        flex-wrap: wrap;
        justify-content: center;
    }
    .hero_col_right,
    .hero_col_left {
        text-align: center;
        width: 100%;
    }
    .hero_col_left {
        text-align: center;
        padding: 25px 15px;
    }
    .hero_col_left h1 {
        font-size: 1.6rem;
    }
    .hero_col_left .cs_btn {
        margin: 10px auto;
    }
    .hero_col_left p {
        padding: 12px 0;
    }
    .hero_col_right img {
        max-width: 95%;
        margin: 15px auto 20px auto;
    }
    /*  */
    .section_ h3 {
        font-size: 1.5rem;
        padding-bottom: 15px;
    }
    .section_ p {
        padding: 7px 0;
        font-size: 15px;
    }
    .section_ .cs_row {
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    .left_col .cs_btn,
    .right_col .cs_btn {
        margin: 12px auto;
    }
    .left_col,
    .right_col {
        width: 100%;
        text-align: center;
    }
    .section_ .right_col img {
        width: 95%;
        margin-bottom: 20px;
    }
    .section_ {
        position: relative;
        padding: 12px 0;
        margin: 30px auto 30px auto;
    }
    .go-to-top {
        bottom: 26px;
        right: 4px;
        width: 34px;
        height: 34px;
    }
    .go-to-top * {
        width: 24px;
        height: 23px;
        font-size: 21px !important;
    }
    /*  */
    #courseDetails,
    #courseDetails .cs_row {
        padding: 12px 0;
    }
    .sec_heading h2 {
        font-size: 1.6rem;
    }
    .lesson_card {
        /* width: 100%; */
        flex-direction: column;
        justify-content: center;
    }
    .thumbnail {
        width: 100%;
        text-align: center;
    }
    .thumbnail img {
        height: auto;
        max-width: 200px;
    }
    .about_lesson_card_right {
        padding: 12px 10px;
    }
    .about_lesson_card_right h4 {
        font-size: 15px;
    }
    .about_lesson_card_right p {
        font-size: 14px;
    }
    /*  */
    .login_page {
        min-height: 75vh;
        padding: 40px 0 25px 0;
    }
    .login_form,
    .cs_form {
        max-width: 95%;
        min-width: 350px;
        padding: 12px 19px;
    }
    .login_form .cs_row,
    .cs_form .cs_row,
    .form_label_wrapper {
        flex-wrap: wrap;
    }
    .login_form .py-2,
    .cs_form .py-2 {
        padding: 2px 0;
    }
    .login_form .cs_btn.outlined .cs_icons {
        font-size: 20px;
    }
    .login_form .cs_btn {
        margin-top: 10px;
    }
    /*  */
    .cs_alert_wrapper p {
        font-size: 12px;
    }
    /*  */
    .course_main_row {
        padding: 12px 0;
        justify-content: center;
        flex-wrap: wrap;
    }
    .course_left {
        position: unset;
        width: 100%;
        order: 2;
    }
    .course_right {
        width: 100%;
        order: 1;
    }
    .course_video_wrapper {
        min-width: 100%;
        min-height: 180px;
    }
    .course_description {
        padding: 15px 0;
    }
    .course_description h1 {
        font-size: 1.6rem;
    }
    .dashboard_container .cs_container {
        padding: 0 !important;
    }
    .course_right {
        margin-left: 0;
    }
    .all_course_list {
        width: 100%;
    }
    .all_course_list header {
        font-size: 17px;
    }
    .course_topics_body {
        max-height: 270px;
    }
    .course_topics_title_box p {
        font-size: 14px;
    }
    .course_topics_title_box h3 {
        font-size: 15px;
    }
    .lang-btn {
        margin: 4px 4px 2px 4px;
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
    }
    .lang-btn span {
        height: 30px;
    }
}

@media (max-width: 450px) {
    .cs_modal {
        width: 85%;
        min-width: 85%;
        max-width: 92%;
        min-height: 75%;
        max-height: 95%;
    }
    .cs_icon_btn {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        max-height: 30px;
        min-height: 30px;
        font-size: 20px;
    }
    .cs_icon_btn.sm {
        width: 24px;
        max-width: 24px;
        min-width: 24px;
        height: 24px;
        max-height: 24px;
        min-height: 24px;
        font-size: 17px;
    }
    .course_video_wrapper {
        min-width: 100%;
        min-height: 120px;
    }
}

@media (max-width: 410px) {
    .lesson_card {
        width: 100%;
    }
}

@media (max-width: 370px) {
    .header_logo img:not(.mobile_logo) {
        display: none;
    }
    .header_logo img.mobile_logo {
        display: block;
        max-width: 50px;
    }
    .copyright_text * {
        font-size: 12px !important;
    }
    /*  */
    .login_form,
    .cs_form {
        max-width: 95%;
        min-width: 95%;
    }
}