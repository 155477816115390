#faqs {
    margin: 70px auto;
}

.faqs_container {
    padding: 0 0 0 35px;
}

.faq-wrapper {
    margin: 17px auto;
    border-radius: 4px;
    border: 1px solid #c3c4c383;
    overflow: hidden;
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
    background-color: #EDF8F4
}

.faq-qs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 9px 10px 9px 12px;
    cursor: pointer;
    user-select: none;
}

.faq-qs h6 {
    font-weight: 500;
    font-size: 18px;
    width: 98%;
    padding-right: 8px;
    margin: 0
}

.faqs-answer {
    height: 0;
    overflow: hidden;
    padding: 0 15px;
    color: var(--text-color);
    font-weight: 500;
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
}

.active-faq .faqs-answer {
    padding: 15px;
    height: auto;
}

.faq-arrow svg {
    width: 20px;
    height: 20px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.faq-arrow {
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 4px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px;
    border: 1px solid rgba(71, 75, 255, 0.2);
}

.active-faq .faq-arrow svg {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    margin: 0;
    padding: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media screen and (max-width:768px) {
    #faq {
        margin-top: 30px;
    }
    .faqs_container {
        padding: 0 0 0 0;
    }
}

@media screen and (max-width:400px) {
    .faqs-answer p,
    .faq-qs h6 {
        font-size: 15px;
    }
}