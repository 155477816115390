.web_footer {
    margin-top: auto;
    width: 100%;
    /* background-color: rgb(240, 240, 240); */
    background-color: var(--primary);
    color: var(--light);
    box-shadow: 0 -4px 10px #00000005;
}

.footer_row {
    padding: 85px 15px 50px 15px;
}

.footer_col {
    /* width: 50%; */
    /* width: 25%; */
    margin-right: 10px;
}

.footer_col {
    padding-right: 22px;
}

.footer_social_wrapper {
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.ft_col_2 {
    margin-left: auto;
}

.footer_logo img {
    max-width: 290px;
}

.cs_ft_social_btn {
    cursor: pointer;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--light);
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.144);
    border: 1px solid var(--primary);
    color: var(--primary);
}

.footer_col .footer_col_head {
    /* color: var(--dark); */
    font-size: 23px;
}

.footer_col ul {
    list-style: none;
}

.footer_col ul li a {
    font-weight: 400;
    cursor: pointer;
    color: var(--light);
}

.footer_col ul li {
    padding: 3px 5px 3px 0;
    display: block;
}

.copyright_area {
    padding: 7px 0;
    background-color: var(--primary);
    border-top: 1px solid var(--light);
}

.copyright_text {
    width: 100%;
    text-align: center;
}

.copyright_text * {
    font-size: 17px;
    color: var(--light);
}

.web_footer a,
.copyright_text a {
    /* color: var(--primary); */
    text-decoration: none;
    cursor: pointer;
}

.copyright_text a:hover {
    text-decoration: none;
}

.copyright_text p {
    margin: 0 auto !important;
    width: 100%;
    text-align: center;
}

.copyright_text {
    /* color: #6c757d */
}

@media (max-width: 768px) {
    .web_footer p {
        font-size: 15px;
        margin-top: 15px;
    }
    .footer_row {
        padding: 45px 0px 25px 0;
        flex-wrap: wrap;
    }
    .footer_col {
        width: 100%;
        margin: 15px auto;
    }
    .footer_col {
        padding: 0 0;
    }
    .cs_ft_social_btn {
        cursor: pointer;
        width: 34px;
        height: 34px;
        margin-right: 7px;
    }
    .footer_col .footer_col_head {
        font-size: 20px;
    }
    .footer_col ul li a {
        font-size: 15px;
    }
    .footer_copy_area {
        padding: 10px 15px 15px 10px;
        max-width: 95%;
    }
    .footer_copy_area p {
        font-size: 14px;
        margin: 0 auto;
    }
    .footer_logo img {
        max-width: 270px;
    }
}

@media screen and (max-width: 495px) {
    .footer_name {
        width: 100%;
        display: block;
    }
}