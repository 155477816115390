.dashboardContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    max-height: 100vh;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.144);
}

.dashboard_sideBar {
    background-color: #fafafa;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.39);
    min-width: 78px;
}

.dashboard_nav {
    padding: 12px 0 12px 0;
    height: 100%;
    flex: 1;
}

.activeLinkDashTab {
    position: relative;
}

.activeLinkDashTab::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: var(--primary);
    pointer-events: none;
}

.dashboardLinks .activeLinkDashTab::before {
    left: -18px;
}

.dashboardLinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 7px 18px 7px 18px;
    /* margin: 5px auto; */
    width: 100%;
    position: relative;
    color: var(--black);
    font-weight: 600;
    font-size: 16px;
}

.dashboardLinks * {
    font-size: 28px;
}

.dashboardLinks:hover {
    color: var(--primary);
}

.dashboardLinks.activeLinkDashTab {
    color: var(--primary);
    background-color: #32e0d438;
}

.dasboardLinks img {
    width: 100%;
}

.dashboard_logo {
    padding: 12px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.dashboard_logo img {
    max-width: 63px;
}

.dashboard_right_col_main {
    position: relative;
    width: 100%;
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-height: 100%;
}


/*  */

.dashboard_header {
    background-color: #fafafa;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: -5px 1px 13px 2px rgba(0, 0, 0, 0.308);
    height: 68px;
}

.dashboard_header_container {
    padding: 8px 18px;
    background: #fafafa;
}

.dashboard_container {
    padding: 12px 18px;
    margin-top: 7px;
    height: calc(100% - 75px);
    flex: 1;
    overflow: auto;
    scroll-behavior: smooth;
}

.dashboardToolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 7px 0;
    margin-left: auto;
}

.dashboardMenuOpen {
    display: none;
}

.toolbarIconBox {
    margin: 0 10px 0 4px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.adminAvatarBox {
    width: 42px;
    height: 42px;
    border: 1px solid rgba(51, 50, 50, 0.281);
}

.toolbarIconBox:hover {
    background-color: rgba(152, 232, 247, 0.144);
}

.toolbarIconBox .adminAvatar {
    border-radius: 50%;
    max-width: 39px;
    min-width: 36px;
    min-height: 36px;
    max-height: 39px;
}

.icon_ {
    color: var(--primary);
    font-size: 23px;
}

.text_links_ds {
    cursor: pointer;
    user-select: none;
}

.cs_progress_wrapper {
    border-radius: 50px;
    background: rgba(128, 128, 128, 0.514);
    height: 8px;
    overflow: hidden;
    width: 100%;
    min-width: 550px;
    flex: 1;
}

.cs_progress {
    border-radius: 50px;
    background: var(--primary);
    height: 8px;
}

.cs_form .cs_progress_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    border-radius: 0 0px 9px 9px
}


/* profile */

.profile_form {
    max-width: 950px;
    margin: 10px auto;
    background-color: var(--light);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
    border-radius: 6px;
    padding: 12px 15px;
}

.col_50 {
    width: 50%;
}

.col_100 {
    width: 100%;
}

.form_input_right_col {
    padding-left: 8px;
}

.selectImg-wrapper {
    border: 2px dashed var(--primary);
    width: 135px;
    height: 129px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.selectImg-wrapper input {
    display: none;
}

.selectImg-wrapper img {
    width: 100%;
    object-fit: contain;
}

.img-picker {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 111;
    cursor: pointer;
    color: var(--primary);
}

.img-picker svg {
    width: 100%;
}

.remove-img-btn {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 27px;
    height: 27px;
    background-color: red;
    color: #fff;
    font-size: 24px;
    /* padding: 4px; */
    border-radius: 50%;
    cursor: pointer;
}

.only_read {
    /* pointer-events: none; */
    cursor: not-allowed;
    user-select: none;
    opacity: 0.8;
    background-color: #a9fff981;
}

.watched_percentage_wrapper {
    align-items: center;
    margin: 18px auto;
}

.watched_percentage_wrapper .cs_progress_wrapper {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    flex: 1;
}

.course_del_btn {
    top: auto !important;
    bottom: 4px;
    background-color: rgb(224, 73, 73);
}


/*  */

.users_card {
    max-width: 200px;
    margin-right: 6px;
}

.users_card p {
    text-overflow: ellipsis;
    overflow: hidden;
}

.p {
    padding: 15px 0;
    min-height: 200px;
    padding: 16px;
    font-size: 17px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.733);
}

.hoover {
    position: relative;
    margin-right: 12px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
}

.hoover.options {
    color: red
}

.hoover .previewSpan,
.hoover input {
    position: absolute;
    top: -34px;
    width: 200px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    background: #fff;
    height: 35px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.205);
    border: 1px solid rgba(0, 0, 0, 0.39);
    border-radius: 5px;
}

.hoover:active input,
.hoover:focus input,
.hoover:hover input,
.hoover:active .previewSpan,
.hoover:focus .previewSpan,
.hoover:hover .previewSpan {
    opacity: 1;
    pointer-events: auto;
    color: #000;
}

.previewSpan {
    padding: 0 6px;
}

.tabs-row .cs_btn {
    border: 1px solid var(--primary);
    background-color: var(--white);
    color: var(--black);
}

.tabs-row .active-tab-btn {
    background-color: var(--primary);
    color: var(--white);
}

.tabs-row .cs_btn:hover {
    transform: none!important;
}

.tabs-row .cs_btn:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.tabs-row .cs_btn:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.tabs-row .cs_btn {
    margin: 12px 0;
}

@media (max-width: 768px) {
    .users_card {
        width: 49.2%;
        max-width: 49.2%;
        margin-right: 3px;
    }
    .dashboard_logo {
        /* display: none; */
    }
    .text_links_ds,
    .dashboard_title {
        display: none;
    }
    .dashboard_container {
        padding: 14px 14px 12px 14px;
    }
    .dashboard_sideBar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 999;
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        transition: -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        -o-transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        min-width: 67px;
        max-width: 72px;
    }
    .sidebar_backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgb(0 0 0/18%);
        z-index: 999;
    }
    .dashboard_nav {
        /* padding: 80px 0 12px 0; */
        padding: 8px 0 12px 0;
    }
    .dashboardSideBar_opened.dashboard_sideBar {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
    .dashboardMenuOpen {
        width: 43px;
        height: 39px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        color: var(--dark);
        margin: 4px 10px 4px 6px;
    }
    .dashboardMenuOpen * {
        font-size: 30px !important;
    }
    .dashboard_right_col_main {
        padding: 0;
    }
    .dashboard_header {
        margin-right: 0;
    }
}

@media screen and (max-width: 425px) {
    .users_card {
        width: 100%;
        max-width: 100%;
        margin: 3px auto;
    }
}